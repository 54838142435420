/* ------------------------------------------
    STYLES FOR HOME COMPONENT
------------------------------------------- */
.container.home-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .text-zone {
    flex: 1;
    margin-left: 10%;
    margin-top: 14%;

    h1 {
      color: white;
      font-size: 5.5rem;
      line-height: 62px;
      margin: 10px 0;
    }

    h2 {
      color: #a26c6c;
      font-size: 11px;
      font-family: sans-serif;
      letter-spacing: 3px;
      margin-top: 20px;
      animation: fadeIn 1s 1.8s backwards;
    }

    /* Style for the real button */
    .flat-button {
      background-color: transparent; /* or pick a color */
      color: #0ee6e6;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #085777;
      margin-top: 20px;
      display: inline-block;
      border-radius: 0.5rem;
      white-space: nowrap;
      width: fit-content;
      cursor: pointer; /* ensure pointer on hover */
      transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

      &:hover {
        background: #c6520b;
        color: rgb(0, 0, 0);
      }
    }
  }

  /* LOGO CONTAINER on Desktop */
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;

    /* If the <Logo /> component contains an <img>, we can style it like so: */
    img {
      max-width: 100%;
      height: auto;
      margin-top: -45%;
    }
  }
}

/* ------------------------------------------
    MOBILE / TABLET
------------------------------------------- */
@media (max-width: 768px) {
  .container.home-page {
    flex-direction: column;  /* stack vertically */
    align-items: center;

    .text-zone {
      margin-left: 0;
      margin-top: 0;
      width: 100%;
      text-align: center;
      padding: 15px;

      h1 {
        font-size: 2rem;
        line-height: 1.2;
      }
      h2 {
        font-size: 1rem;
      }

      .flat-button {
        margin: 20px auto 0; /* top auto bottom, centers horizontally */
        display: block;
        width: fit-content;
      }
    }

    /* LOGO CONTAINER on mobile */
    .logo-container {
      /* 
        If there's not enough space, the bottom part of the image 
        will be cut off (disappear downward) instead of to the left
      */
      width: 100%;
      max-height: 50vh;    /* limit vertical space for the image container */
      overflow: hidden;    /* anything that doesn't fit is hidden (cut off) */
      margin-top: 1.5rem;  /* space so it doesn't overlap with text */

      display: flex;
      justify-content: center;
      align-items: flex-start; 
        /* "flex-start" so the image is pinned to the top, 
           and any cutoff happens at the bottom */

      img {
        width: 100%;        /* fill container width */
        height: auto;       /* keep aspect ratio */
        object-fit: cover;  /* crop/cut off bottom if not enough space */
        margin-left: 101%;
        margin-top: 15%;
      }
    }
  }
}



.typewriter {
  color: rgb(9, 191, 163); /* Set typewriter text to blue */
 }
 
 
 